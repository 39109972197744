
import { Component, Vue } from 'vue-property-decorator'
import { DataList, SearchInfo } from '@/types/workcard'

@Component({
  name: 'WordCardList'
})
export default class BraceletList extends Vue {
  private tableData: DataList[] =[]

  private info: SearchInfo= {
    projectName: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.workcard.selectSumProjectList, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'workcardDetailMap',
      params: { projectId: id }
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
